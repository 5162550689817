export const getOffers = async () => {
    if (sessionStorage.getItem("offers")) {
        return JSON.parse(sessionStorage.getItem("offers"));
    }

    const offersRequest = await fetch("/api/offers");
    const offers = await offersRequest.json();
    sessionStorage.setItem("offers", JSON.stringify(offers));

    return offers;
};
