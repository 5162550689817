import algoliasearch from "algoliasearch/lite";
import {
    InstantSearch,
    SearchBox,
    Hits,
    Highlight,
    RefinementList,
    Stats,
    CurrentRefinements,
    Pagination,
    PoweredBy
} from "react-instantsearch";

const searchClient = algoliasearch("5XZF210X0O", "e8525c4e87143ebce600e0af441bbbe7");

function Hit({ hit }) {
    const description =
        hit.description.length > 0 ? hit.description.split(" ").slice(0, 10).join(" ") + "..." : hit.description;

    // add utm_term and utm_source to url
    hit.url = hit.url + "?utm_source=search";

    return (
        <a className={"hit-item"} href={hit.url}>
            <img src={hit.image} alt={hit.name} className={"hit-image"} />
            <div className={"hit-content"}>
                <strong className={"hit-name"}>
                    <Highlight attribute="name" hit={hit} />
                </strong>
                <p className={"hit-brand-name"}>{hit.brand}</p>
                <p className={"hit-description"}>{description}</p>
                <p className={"hit-price"}>
                    {hit.price.toLocaleString("sv-se")} <span>kr</span>
                </p>
            </div>
        </a>
    );
}

export const SearchView = () => {
    return (
        <InstantSearch indexName="products" searchClient={searchClient}>
            <SearchBox placeholder={"Sök efter hemmabioprodukter ..."} />

            <Stats />
            <CurrentRefinements />
            <div className="row">
                <div className="content M10">
                    <Hits
                        hitComponent={Hit}
                        style={{
                            marginTop: "1rem"
                        }}
                    />
                </div>
                <div className="content M2">
                    <h5>Varumärken</h5>
                    <RefinementList attribute="brand" showMore />
                    <h5>Kategorier</h5>
                    <RefinementList attribute="categories" showMore />
                    <h5>Taggar</h5>
                    <RefinementList attribute="tags" showMore />
                    <h5>Butiker</h5>
                    <RefinementList attribute="store" showMore />
                </div>
            </div>
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "1rem"
                }}>
                <Pagination />
            </div>

            <p
                style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "1rem"
                }}>
                <PoweredBy />
            </p>
        </InstantSearch>
    );
};
