import { useEffect } from "react";

export const ImageModal = ({ image, onClose }) => {
    useEffect(() => {
        const handleClickOutside = (e) => {
            if (e.target.className === "modal") {
                onClose();
            }
        };
        const handleEscape = (e) => {
            if (e.key === "Escape") {
                onClose();
            }
        };

        window.addEventListener("click", handleClickOutside);
        window.addEventListener("keydown", handleEscape);

        return () => {
            window.removeEventListener("keydown", handleEscape);
            window.removeEventListener("click", handleClickOutside);
        };
    }, []);

    return (
        <div className="modal">
            <div className="modal-content">
                <img src={image} alt={image} />
            </div>
            <span className="close" onClick={onClose}>
                &times;
            </span>
        </div>
    );
};
