import "./styles/styles.scss";
import { createRoot } from "react-dom/client";
import { SearchView } from "./components/SearchView";
import { ProductImages } from "./components/ProductImages";
import { LoginForm } from "./components/LoginForm";
import { Autocomplete } from "./components/AutoComplete";
import { getAlgoliaResults } from "@algolia/autocomplete-js";
import { ProductItem } from "./components/ProductItem";
import algoliasearch from "algoliasearch/lite";
import { getUserToken } from "./core/user";
import { Offers } from "./components/Offers";

/* LocalStorage get and set with ttl */

const wrapElementIn = (el, wrapper) => {
    el.parentNode.insertBefore(wrapper, el);
    wrapper.appendChild(el);
};

let tables = document.querySelectorAll("table");
let tableWrapper = document.createElement("div");
tableWrapper.classList.add("table-wrapper");
tables.forEach((table) => wrapElementIn(table, tableWrapper));

let toggles = document.querySelectorAll(".toggle");
toggles.forEach((toggle) => {
    toggle.addEventListener("click", (event) => {
        event.preventDefault();
        let targetElement = document.querySelector(toggle.getAttribute("href"));
        if (targetElement) {
            targetElement.classList.toggle("show");
        }
    });
});

const setActiveInNav = () => {
    document.querySelectorAll(".main-navigation > li > a").forEach((link) => {
        const parent = link.parentElement;
        const parts = location.pathname.split("/").filter((path) => path.length > 0);
        let href = link.getAttribute("href");
        let expected = parts.length > 0 ? "/" + decodeURIComponent(parts[0]) : "/";
        href.startsWith(expected) && href.length === expected.length && parent.classList.add("active");
    });
};

document.addEventListener("DOMContentLoaded", () => {
    setActiveInNav();
});

const menuToggler = document.querySelector(".menu-toggle");
const sidebarMenu = document.querySelector("#sidebar-menu");
if (menuToggler && sidebarMenu) {
    menuToggler.addEventListener("click", (event) => {
        event.preventDefault();
        sidebarMenu.classList.toggle("expand");

        menuToggler.querySelector("svg").innerHTML = sidebarMenu.classList.contains("expand")
            ? feather.icons.x.toSvg()
            : feather.icons.menu.toSvg();
    });
}

// If a link is clicked in sidebarMenu, we want to close the menu
sidebarMenu.addEventListener("click", (event) => {
    if (event.target.tagName === "A") {
        setTimeout(() => {
            sidebarMenu.classList.remove("expand");
            menuToggler.querySelector("svg").innerHTML = feather.icons.menu.toSvg();
        }, 100);
    }
});

const productImages = document.querySelectorAll(".product-image");
if (productImages.length > 0) {
    // convert to array and get src attribute
    const urls = Array.from(productImages).map((img) => img.getAttribute("src"));
    const imageWrapper = document.getElementById("product-images");

    if (imageWrapper) {
        const root = createRoot(imageWrapper);
        root.render(<ProductImages images={urls} />);
    }
}

const getModal = (instance) => {
    let existingModal = document.getElementById(instance);
    if (!existingModal) {
        existingModal = document.createElement("div");
        existingModal.id = instance;
        existingModal.classList.add("modal-wrapper");
        document.body.appendChild(existingModal);
    }

    const escClose = (event) => {
        if (event.key === "Escape") {
            existingModal.remove();
        }
    };

    document.addEventListener("keydown", escClose);
    existingModal.addEventListener("click", (event) => {
        if (event.target === existingModal) {
            existingModal.remove();
        }
    });

    return existingModal;
};

const getAffiliateUrl = (href) => {
    for (name in affiliateUrlMap) {
        if (affiliateUrlMap.hasOwnProperty(name)) {
            if (href.match(affiliateUrlMap[name])) {
                if (affiliateDirectLinks.hasOwnProperty(name)) {
                    href = affiliateDirectLinks[name].replace("%s", encodeURIComponent(href));
                }
                break;
            }
        }
    }
    return href;
};

(async () => {
    let searchView = document.getElementById("search-view");
    if (searchView) {
        const root = createRoot(searchView);
        root.render(<SearchView />);
    }
})();

const clamps = document.querySelectorAll(".clamp");
clamps.forEach((clamp) => {
    // Add toggle clapm on click
    clamp.addEventListener("click", (event) => {
        event.preventDefault();
        clamp.classList.toggle("clamp--expanded");
    });
});

const collapsables = document.querySelectorAll(".collapsable");
collapsables.forEach((collapsable) => {
    // Collapsable is a UL and should only show the first 10 items, hide the rest and add a toggle
    const items = collapsable.querySelectorAll("li");
    if (items.length > 10) {
        items.forEach((item, index) => {
            if (index > 9) {
                item.classList.add("hidden");
            }
        });
        const toggle = document.createElement("a");
        toggle.classList.add("toggle");
        toggle.innerText = "Visa alla";
        toggle.addEventListener("click", (event) => {
            event.preventDefault();
            items.forEach((item) => {
                item.classList.remove("hidden");
            });
            toggle.remove();
        });
        // collapsable.appendChild(toggle);
        // add toggle after list collapsable
        collapsable.parentElement.insertBefore(toggle, collapsable.nextSibling);
    }
});

const getReferrer = () => {
    const referrer = document.referrer;
    if (referrer) {
        // Only if the referrer is the same domain, we want to use it
        if (referrer.includes(location.hostname)) {
            // first, we only need the path
            const path = new URL(referrer).pathname;

            // only if the path is not the same as the current path
            if (path !== location.pathname) {
                return path;
            }
        }
    }
    return null;
};

const prefetchAfterHoverDelay = (element) => {
    const href = element.getAttribute("href");
    // We only want to prefetch when user hovers over the link for 100ms

    let hoverTimeout;

    element.addEventListener("mouseover", (event) => {
        hoverTimeout = setTimeout(() => {
            const link = document.createElement("link");
            link.rel = "prefetch";
            link.href = href;
            document.head.appendChild(link);
        }, 200);
    });

    element.addEventListener("mouseout", (event) => {
        clearTimeout(hoverTimeout);
    });
};

const prefetchLinks = document.querySelectorAll("a");
prefetchLinks.forEach((link) => prefetchAfterHoverDelay(link));

const loginForm = document.getElementById("loginForm");
if (loginForm) {
    const formRoot = createRoot(loginForm);
    formRoot.render(<LoginForm fromUrl={getReferrer()} />);
}

const autocomplete = document.getElementById("autocomplete");
// check if we are on /hitta
const isSearchPage = location.pathname === "/hitta";
if (autocomplete && !isSearchPage) {
    const appId = "5XZF210X0O";
    const apiKey = "e8525c4e87143ebce600e0af441bbbe7";
    const searchClient = algoliasearch(appId, apiKey);
    const root = createRoot(autocomplete);

    root.render(
        <Autocomplete
            placeholder={"Sök efter hemmabioprodukter ..."}
            openOnFocus={true}
            getSources={({ query }) => [
                {
                    sourceId: "products",
                    getItems() {
                        return getAlgoliaResults({
                            searchClient,
                            queries: [
                                {
                                    indexName: "products",
                                    query,
                                    params: {
                                        hitsPerPage: 10,
                                        enablePersonalization: true,
                                        userToken: getUserToken()
                                    }
                                }
                            ]
                        });
                    },
                    templates: {
                        item({ item, components }) {
                            return <ProductItem hit={item} components={components} />;
                        },
                        noResults() {
                            return "Inga resultat";
                        }
                    }
                }
            ]}
        />
    );
}

const offers = document.getElementById("offers");
if (offers) {
    const root = createRoot(offers);
    root.render(<Offers />);
}
