import { useState } from "react";
import { ImageModal } from "./ImageModal";

export const ProductImages = ({ images = [] }) => {
    const [selected, setSelected] = useState(images[0]);
    const [showModal, setShowModal] = useState(false);
    return (
        <>
            <div className="main-image">
                <img
                    onClick={() => {
                        setShowModal(true);
                    }}
                    className={"large"}
                    src={selected}
                    alt={selected}
                />
            </div>
            {images.length > 1 && (
                <div className="gallery">
                    {images.map((image, index) => {
                        return (
                            <img
                                onClick={() => {
                                    setSelected(image);
                                }}
                                src={image}
                                key={index}
                                alt={image}
                                className={`small ${selected === image ? "active" : null}`}
                            />
                        );
                    })}
                </div>
            )}

            {showModal && <ImageModal image={selected} onClose={() => setShowModal(false)} />}
        </>
    );
};
