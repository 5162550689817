import { useState } from "react";

export const LoginForm = ({ fromUrl = "/" }) => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    const [loggedIn, setLoggedIn] = useState(false);

    const handleLogin = async (e) => {
        setError("");
        e.preventDefault();
        setLoading(true);

        const payload = {
            email,
            password
        };

        if (email === "" || password === "") {
            setError("Please fill in all fields");
            setLoading(false);
        }

        try {
            const response = await fetch("/login", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(payload)
            });
        } catch (error) {
            setError("An error occurred");
        }
    };

    return (
        <form class={"stack-form"}>
            {error.length > 0 && <p class={"error"}>{error}</p>}
            <div>
                <label htmlFor="email">Username</label>
                <input
                    type="email"
                    placeholder="Din e-postadress ..."
                    id="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
            </div>
            <div>
                <label htmlFor="password">Password</label>
                <input
                    type="password"
                    placeholder="Ditt säkra lösenord ..."
                    id="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                />
            </div>
            <div>
                <button type="submit" class={"btn btn-block"} onClick={handleLogin}>
                    {loading ? "Loading..." : "Login"}
                </button>
            </div>
        </form>
    );
};
