import { useState, useEffect } from "react";
import { getOffers } from "../core/offers";

export const Offers = () => {
    const [offers, setOffers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [toggleTerms, setToggleTerms] = useState(false);

    useEffect(() => {
        const fetchOffers = async () => {
            const offers = await getOffers();
            setOffers(offers.offers);
            setLoading(false);
        };

        fetchOffers().catch((error) => {
            console.error(error);
            setLoading(false);
        });
    }, []);

    return offers.length > 0 ? (
        <div className={"offers-wrapper"}>
            {loading && <p>Loading...</p>}
            <div className={"offers-header"}>Erbjudanden</div>
            <ul>
                {offers.map((offer) => (
                    <li key={offer.offerId}>
                        <a href={offer.trackingURL} target={"_blank"} rel={"nofollow noreferrer"}>
                            <h2>{offer.programName}</h2>
                            <img src={offer.logoURL} alt={offer.programName} />
                            <p>{offer.offerDescription}</p>
                            <p className={"offer-domain"}>{offer.programUrl}</p>
                        </a>
                        <p className={"offer-terms"}>
                            {toggleTerms ? (
                                offer.offerTerms
                            ) : (
                                <a
                                    href={"#terms"}
                                    onClick={(event) => {
                                        event.preventDefault();
                                        setToggleTerms(true);
                                    }}>
                                    Terms
                                </a>
                            )}
                        </p>
                    </li>
                ))}
            </ul>
        </div>
    ) : null;
};
