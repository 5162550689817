export const getUserToken = () => {
    // if user token is already in localStorage
    let userToken = localStorage.getItem("user-token");
    if (userToken) {
        return userToken;
    }

    // Generate UID
    let uid = Math.random().toString(36).substring(2) + Date.now().toString(36);

    // Store UID in localStorage
    localStorage.setItem("user-token", uid);

    return uid;
};
