import React from "react";

export function ProductItem({ hit, components }) {
    return (
        <a href={hit.url} className="aa-ItemLink">
            <div className="aa-ItemContent">
                <div className="hit-image">
                    <img src={hit.image} alt={hit.name} />
                </div>
                <div className="aa-ItemTitle">
                    <components.Highlight hit={hit} attribute="name" />
                    <div className={"hit-small"}>
                        {hit.categories.join(", ")} - {hit.tags.join(", ")}
                    </div>
                </div>
            </div>
        </a>
    );
}
